import { TabDto } from 'modules/user/types'

// TODO: MOVE THIS OUT OF CORE MODULE
export const adminPageLayoutTabs: TabDto[] = [
  {
    id: 'users',
    name: 'Users',
    path: 'users',
    fullPath: '/admin/users',
    static: true,
    useFullPath: true,
  },
  {
    id: 'invites',
    name: 'Invites',
    path: 'invites',
    fullPath: '/admin/invites',
    static: true,
    useFullPath: true,
  },
  {
    id: 'organizations',
    name: 'Organizations',
    path: 'organizations',
    fullPath: '/admin/organizations',
    static: true,
    useFullPath: true,
  },
]
