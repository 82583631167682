import { useCallback, useEffect, useState } from 'react'
// hooks
import { usePlaylist } from 'modules/playlist/hooks'
import { useDebounce, useFilter, usePageScrolling } from 'modules/core/hooks'
// components
import { FilterModal, LoaderContainer } from 'modules/core/components'
import { PlaylistPreview, PlaylistToolbar } from 'modules/playlist/components'
// constants
import { PLAYLIST_PAGE_LIMIT } from 'modules/playlist/constants'
// styled
import { HorizontalLoader } from 'modules/core/styled'
// types
import { PlaylistFilterOptions } from 'modules/playlist/types'

import {
  AssetWrapper,
  PlaylistWrapper,
  FilterContainer,
  Root,
} from './Playlist.styled'

export const Playlist = () => {
  const { filterInfo, setFilterData, handleResetFilters } =
    useFilter<PlaylistFilterOptions>({
      filterOpen: { type: 'boolean', default: false },
      competition: { type: 'toggle', default: undefined },
      startDate: { type: 'input', default: '' },
      endDate: { type: 'input', default: '' },
      events: { type: 'set', default: new Set() },
      sportId: { type: 'input', default: undefined },
    })
  const [searchValue, setSearchValue] = useState<string>('')
  // hooks
  const debounceSearchValue = useDebounce(searchValue, 500)
  const playList = usePlaylist({
    expand: ['assets', 'assets.event', 'assets.media_locator'],
    search_by: ['title', 'description'],
    page_limit: PLAYLIST_PAGE_LIMIT,
    search: debounceSearchValue,
  })
  const { containerRef, isBottom, resetIsBottom } = usePageScrolling(100)

  const handleOpenFilter = useCallback(() => {
    setFilterData({
      type: 'filterOpen',
      value: !filterInfo.filterOpen,
    })
  }, [filterInfo.filterOpen, setFilterData])

  useEffect(() => {
    if (
      isBottom &&
      playList.hasNextPage &&
      !playList.isFetchingNextPage &&
      !playList.isLoading
    ) {
      resetIsBottom()
      playList.fetchNextPage()
    }
  }, [playList, isBottom, resetIsBottom])

  return (
    <Root>
      <FilterContainer>
        <FilterModal
          open={filterInfo.filterOpen as boolean}
          filterData={filterInfo}
          setFilterData={setFilterData}
          resetFilter={handleResetFilters}
          fields={['events']}
        />
      </FilterContainer>
      <PlaylistToolbar
        search={searchValue}
        setSearch={setSearchValue}
        filterOpen={filterInfo.filterOpen as boolean}
        handleFilterToggle={handleOpenFilter}
      />
      <PlaylistWrapper>
        <AssetWrapper ref={containerRef}>
          <LoaderContainer isLoading={playList.isLoading}>
            {playList.data?.pages?.map(page =>
              page?.results.map(playlistItem => (
                <PlaylistPreview
                  key={playlistItem.id}
                  playlist={playlistItem}
                  filter={{
                    events: Array.from(filterInfo.events as Set<string>),
                    sportId: filterInfo.sportId as string,
                  }}
                />
              ))
            )}
          </LoaderContainer>
          {playList.isFetchingNextPage && <HorizontalLoader />}
        </AssetWrapper>
      </PlaylistWrapper>
    </Root>
  )
}
