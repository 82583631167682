import { FC } from 'react'
// Layout
import { LayoutIndex } from 'modules/layout/constants/LayoutIndex'
// Components
import { VideoPlayer } from 'modules/video-player/components'
import { VideoPlayerController } from 'modules/video-player-controller/components'
import {
  Heatmap,
  Minimap,
  Statistics,
  Ticker,
} from 'modules/match-info/components'
import { AssetList } from 'modules/asset/components'
import { Telestration } from 'modules/telestration/components'
import { Playlist } from 'modules/playlist/components'
import { TeamPlayers } from 'modules/team-player/components'
// Context
import { TeamContextProvider } from 'modules/team/provider'
import { MatchPeriodProvider } from 'modules/match-period/provider'
// Types
import { IProviderComponent } from 'modules/layout/components/LayoutItem/LayoutItem.interface'

export const LayoutComponents: Record<
  LayoutIndex,
  { component: FC; provider?: IProviderComponent }
> = {
  [LayoutIndex.PLAYER]: {
    component: VideoPlayer,
  },
  [LayoutIndex.PLAYER_CONTROLS]: {
    component: VideoPlayerController,
  },
  [LayoutIndex.HEATMAP]: {
    component: Heatmap,
    provider: TeamContextProvider,
  },
  [LayoutIndex.MINIMAP]: {
    component: Minimap,
  },
  [LayoutIndex.STATISTICS]: {
    component: Statistics,
    provider: MatchPeriodProvider,
  },
  [LayoutIndex.ASSETS]: {
    component: AssetList,
  },
  [LayoutIndex.TELESTRATION]: {
    component: Telestration,
  },
  [LayoutIndex.PLAYLIST]: {
    component: Playlist,
  },
  [LayoutIndex.PLAYERS]: {
    component: TeamPlayers,
  },
  [LayoutIndex.HIGHLIGHTS]: {
    component: Ticker,
  },
}
