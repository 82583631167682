import {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
  MouseEvent,
} from 'react'
import { Direction, Range } from 'react-range'
// Layout
import {
  componentItemColorsWithDefault,
  LayoutIndexPaletteItem,
} from 'modules/layout/constants'
// Hooks
import { useTranslation } from 'react-i18next'
import {
  useReduxDispatch,
  useReduxSelector,
  useResizeObserver,
} from 'modules/core/hooks'
import { useUserCache, useUserCacheCreate } from 'modules/cache/hooks'
import {
  usePreciseCurrentTime,
  useVideoPlayerControls,
} from 'modules/video-player/hooks'
import { useMatch } from 'modules/match/hooks'
// Context
import { ComponentInfoContext } from 'modules/generic/context'
import { VideoPlayerContext } from 'modules/video-player/context'
// Redux
import {
  selectComponentNumberInLayout,
  selectLayoutCacheById,
  updateLayoutCache,
} from 'modules/layout/redux'
import {
  setMatchType,
  setIsFullscreen,
  selectIsClipSelected,
} from 'modules/video-player/redux'
// Components
import { Fade } from '@mui/material'
import { Menu, MenuItem } from 'modules/core/components'
import { VideoPlayerControls } from 'modules/video-player/components'
import { ToolbarControls } from 'modules/generic/components'
import { ToolbarIcon } from 'modules/generic/components/ToolbarHeader/ToolbarHeader.styled'
import { VideoPlayerSelector } from 'modules/video-player/components'
import { ActionCheckbox, Tooltip } from 'modules/core/styled'
// Utils
import { convertSecondsToTime } from 'modules/core/utils'
import { fetchAvailableMatchLocators, isMatchLive } from 'modules/match/utils'
// Constants
import { EVENT_TYPES } from 'modules/event/constants/eventTypes'
import { TIMELINE_TEXT } from 'modules/video-player-controller/constants'
import { MATCH_LOCATOR_TYPE } from 'modules/match/constants'
// Types
import { MatchLocatorType } from 'modules/match/types/match.types'
import { EventType } from 'modules/event/types'

import {
  Divider,
  VolumeThumb,
  FlexContainer,
  SettingsContainer,
  Playtime,
  ToolbarContainer,
  ToolbarDivider,
  ToolbarWrapper,
  ViewControls,
  ViewIcon,
  VolumeControls,
  VolumeIcon,
  VolumeTooltip,
  VolumeTrackWrapper,
  VolumeTrack,
  MobilePlayerContainer,
  StyledMenuItemContent,
} from './VideoPlayerControllerToolbar.styled'

export const VideoPlayerControllerToolbar = () => {
  const { t } = useTranslation(['components', 'quantity'])
  const { playerId, player } = useContext(VideoPlayerContext)

  const dispatch = useReduxDispatch()
  const { match } = useMatch()

  const {
    layoutIndex,
    componentId: layoutId,
    componentCacheKey,
  } = useContext(ComponentInfoContext)

  const { mutate: userCacheCreate } = useUserCacheCreate()
  const { data } = useUserCache<{
    [key: string]: { visibleTimelines: EventType[] }
  }>({
    keys: [componentCacheKey],
  })

  const componentCache = useReduxSelector(state =>
    selectLayoutCacheById(state, layoutId)
  )

  const layoutComponent = LayoutIndexPaletteItem[layoutIndex]

  const { volume, setVolume } = useVideoPlayerControls(player ?? null)

  const isClipSelected = useReduxSelector(state =>
    selectIsClipSelected(state, playerId ?? '')
  )
  const componentNumberInLayout = useReduxSelector(state =>
    selectComponentNumberInLayout(state, layoutId, layoutIndex)
  )

  const currentTime = usePreciseCurrentTime(player)

  const [matchLocatorContextMenuAnchor, setMatchLocatorContextMenuAnchor] =
    useState<HTMLElement | null>(null)

  const [
    visibleTimelinesContextMenuAnchor,
    setVisibleTimelinesContextMenuAnchor,
  ] = useState<HTMLElement | null>(null)

  const toolbarRef = useRef<HTMLDivElement>(null)
  const { width: toolbarWidth } = useResizeObserver(toolbarRef)

  // TODO: rewrite in container queries
  const isSmallScreen = useMemo(() => toolbarWidth < 700, [toolbarWidth])
  const isExtraSmallScreen = useMemo(() => toolbarWidth < 350, [toolbarWidth])

  const isMatchLocatorContextMenuOpen = Boolean(matchLocatorContextMenuAnchor)
  const isVisibleTimelinesContextMenuOpen = Boolean(
    visibleTimelinesContextMenuAnchor
  )

  const isCurrentMatchLive = useMemo(() => isMatchLive(match), [match])
  const currentAvailableMatchViews = useMemo(
    () => fetchAvailableMatchLocators(match),
    [match]
  )

  const handleViewChange = useCallback(
    (type: MatchLocatorType) => () => {
      const matchMediaLocator = match?.match_assets?.find(
        asset => asset.type === type
      )?.media_locator

      if (matchMediaLocator) {
        dispatch(
          setMatchType({
            matchType: type,
            id: playerId ?? '',
            mediaLocator: matchMediaLocator,
          })
        )
      }

      setMatchLocatorContextMenuAnchor(null)
    },
    [dispatch, playerId, match?.match_assets]
  )

  const handleChangeView = useCallback(() => {
    const previousView = componentCache?.view ?? 'event'

    dispatch(
      updateLayoutCache({
        ...componentCache,
        id: layoutId,
        view: previousView === 'event' ? 'clip' : 'event',
      })
    )
  }, [dispatch, layoutId, componentCache])

  const handleOpenFullscreen = useCallback(
    () =>
      dispatch(
        setIsFullscreen({
          isFullscreen: true,
          id: playerId ?? '',
        })
      ),
    [dispatch, playerId]
  )

  const volumeControls = (
    <VolumeTooltip
      TransitionComponent={Fade}
      placement={'top'}
      title={
        <Range
          step={0.1}
          min={0}
          max={1}
          values={[volume]}
          direction={Direction.Up}
          onChange={([volume]) => setVolume(volume)}
          renderTrack={({ props, children }) => (
            <VolumeTrackWrapper>
              <VolumeTrack
                {...props}
                style={{
                  ...props.style,
                }}
              >
                {children}
              </VolumeTrack>
            </VolumeTrackWrapper>
          )}
          renderThumb={({ props }) => (
            <VolumeThumb
              {...props}
              style={{
                ...props.style,
              }}
            />
          )}
        />
      }
    >
      <VolumeControls>
        <VolumeIcon name='volume-up' />
      </VolumeControls>
    </VolumeTooltip>
  )

  const handleMatchLocatorMenuOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setMatchLocatorContextMenuAnchor(event.currentTarget)
  }

  const handleVisibleTimelinesMenuOpen = (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    setVisibleTimelinesContextMenuAnchor(event.currentTarget)
  }

  const visibleTimelinesCache =
    data?.[componentCacheKey]?.visibleTimelines ?? []

  const isAllTimelinesVisible =
    visibleTimelinesCache.length === Object.values(EVENT_TYPES).length

  const handleToggleAllTimelines = () => {
    const updatedTimelines = isAllTimelinesVisible
      ? []
      : Object.values(EVENT_TYPES)

    userCacheCreate({
      [componentCacheKey]: {
        visibleTimelines: updatedTimelines,
      },
    })
  }

  const handleToggleTimeline = (timeline: EventType) => {
    const updatedTimelines = visibleTimelinesCache.includes(timeline)
      ? visibleTimelinesCache.filter(t => t !== timeline)
      : [...visibleTimelinesCache, timeline]

    userCacheCreate({
      [componentCacheKey]: {
        visibleTimelines: updatedTimelines,
      },
    })
  }

  const viewControls = (
    <ViewControls>
      {volumeControls}
      <Tooltip title={t('components:playerControls.timelines')} placement='top'>
        <span>
          <ViewIcon
            name='timeline'
            onClick={handleVisibleTimelinesMenuOpen}
            sx={{ scale: '1.6' }}
          />
        </span>
      </Tooltip>
      <Tooltip
        title={t('components:playerControls.clipCreate')}
        placement='top'
      >
        <span>
          <ViewIcon
            name='cut'
            active={componentCache?.view === 'clip'}
            onClick={handleChangeView}
          />
        </span>
      </Tooltip>
      <Tooltip
        title={!isClipSelected ? t('components:playerControls.changeView') : ''}
        placement='top'
      >
        <span>
          <ViewIcon
            name='display'
            disabled={isClipSelected}
            onClick={!isClipSelected ? handleMatchLocatorMenuOpen : undefined}
          />
        </span>
      </Tooltip>
      <Tooltip title={t('Fullscreen')} placement='top' arrow>
        <span>
          <ViewIcon name='fullscreen' onClick={handleOpenFullscreen} />
        </span>
      </Tooltip>

      <Menu
        anchorEl={visibleTimelinesContextMenuAnchor}
        open={isVisibleTimelinesContextMenuOpen}
        onClose={() => setVisibleTimelinesContextMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleToggleAllTimelines} disabled={false}>
          <StyledMenuItemContent>
            {t('quantity:all')}
            <ActionCheckbox checked={isAllTimelinesVisible} />
          </StyledMenuItemContent>
        </MenuItem>
        {Object.values(EVENT_TYPES).map(timeline => (
          <MenuItem
            key={timeline}
            onClick={() => handleToggleTimeline(timeline)}
            disabled={false}
          >
            <StyledMenuItemContent>
              {TIMELINE_TEXT[timeline]}
              <ActionCheckbox
                checked={visibleTimelinesCache.includes(timeline)}
              />
            </StyledMenuItemContent>
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={matchLocatorContextMenuAnchor}
        open={isMatchLocatorContextMenuOpen}
        onClose={() => setMatchLocatorContextMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {MATCH_LOCATOR_TYPE.map(({ view, displayName, icon }) => {
          const isViewBroadcastLive = view === 'broadcast_live'

          const isEnabled = isViewBroadcastLive
            ? isCurrentMatchLive
            : currentAvailableMatchViews[view]

          return (
            <MenuItem
              key={view}
              icon={icon}
              onClick={handleViewChange(view)}
              disabled={!isEnabled}
            >
              {displayName}
            </MenuItem>
          )
        })}
      </Menu>
    </ViewControls>
  )

  // TODO: Try rewrite it to grid so we avoid conditional rendering
  return (
    <ToolbarWrapper ref={toolbarRef}>
      <ToolbarContainer>
        <FlexContainer>
          <ToolbarDivider>
            <ToolbarIcon
              name={layoutComponent?.icon ?? 'controls'}
              color={
                componentItemColorsWithDefault[componentNumberInLayout + 1]
              }
            />
            <Divider />
            <VideoPlayerSelector />
          </ToolbarDivider>
          <Playtime>{convertSecondsToTime(currentTime ?? 0)}</Playtime>
        </FlexContainer>

        {/*TODO: return in v2*/}
        {/* <MatchControls>
          <MatchText accent>Half 1</MatchText>
          <MatchText>|</MatchText>
          <MatchText>Half 2</MatchText>
        </MatchControls> */}

        {!isSmallScreen && <VideoPlayerControls player={player ?? null} />}
        <SettingsContainer>
          <div />
          {!isExtraSmallScreen && viewControls}
          <ToolbarControls layoutId={layoutId} layoutIndex={layoutIndex} />
        </SettingsContainer>
      </ToolbarContainer>
      {isExtraSmallScreen && (
        <MobilePlayerContainer>{viewControls}</MobilePlayerContainer>
      )}
      {isSmallScreen && (
        <MobilePlayerContainer>
          <VideoPlayerControls player={player ?? null} />
        </MobilePlayerContainer>
      )}
    </ToolbarWrapper>
  )
}
