import { api } from 'modules/core/api'
import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'
import {
  CreateHighlightBody,
  Highlight,
  HighlightList,
  ListHighlightListOptions,
} from '@sporttotal-tv/dip-coaching-client/dist'

export const MatchInfoApiService = {
  tickerCreate: transformApiResults<CreateHighlightBody, Highlight>(
    api.highlight.create
  ),
  tickerDelete: transformIdApiResults<void, Highlight>(api.highlight.delete),
  tickerList: transformApiResults<ListHighlightListOptions, HighlightList>(
    api.highlight.list
  ),
}
