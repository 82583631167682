import { styled, Typography } from '@mui/material'
import { IconButton } from 'modules/core/components/IconButton'

export const AnimatedChevronIcon = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp: boolean }>`
  transition: all 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
`

export const EventSelectIcon = styled(AnimatedChevronIcon)`
  width: 10px;
  height: 10px;
`

export const EventElement = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'left' && prop !== 'durationWidth' && prop !== 'selected',
})<{
  left: number
  durationWidth: number
  selected?: boolean
}>`
  position: absolute;
  z-index: 3;
  overflow: hidden;
  cursor: pointer;
  column-gap: 10px;
  left: ${({ left }) => left}px;
  min-width: 36px;
  height: 20px;
  transition: all 0.3s;
  background-color: ${({ selected }) =>
    selected ? 'rgba(163, 0, 40,25%)' : 'rgba(150,152,155,20%)'};
  width: ${({ durationWidth }) => durationWidth}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.palette.secondary.main : theme.palette.primary.light};
  border-bottom: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.palette.secondary.main : theme.palette.primary.light};
  border-left: 7px solid
    ${({ theme, selected }) =>
      selected ? theme.palette.secondary.main : theme.palette.primary.light};
  border-right: 7px solid
    ${({ theme, selected }) =>
      selected ? theme.palette.secondary.main : theme.palette.primary.light};
  border-radius: 5px;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    height: 50%;
    width: 3px;
    border-left: 1px solid ${({ theme }) => theme.palette.secondary.light};
    border-right: 1px solid ${({ theme }) => theme.palette.secondary.light};
    transform: translateX(calc(100% + 1.5px));
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 50%;
    width: 3px;
    border-left: 1px solid ${({ theme }) => theme.palette.secondary.light};
    border-right: 1px solid ${({ theme }) => theme.palette.secondary.light};
    transform: translateX(calc(-100% - 1.5px));
  }
`

export const EventElementItems = styled('div')`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
`

export const EventInfoWrapper = styled('div')`
  flex: 1;
  position: relative;
`

export const EventElementWrapper = styled('div')`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
`

export const Divider = styled('div', {
  shouldForwardProp: prop => prop !== 'big',
})<{ big?: boolean }>`
  height: 11px;
  z-index: 2;
  border-right: ${({ big }) => (big ? '3px' : '1px')} solid
    ${({ theme }) => theme.palette.primary.light};
`

export const RowWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.5)};
  margin-bottom: ${({ theme }) => theme.spacing(1.25)};
`

export const RowTitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  user-select: none;
  align-items: end;
  padding-left: ${({ theme }) => theme.spacing(1)};
`

export const RowTitleWithHidingWrapper = styled(RowTitleWrapper)`
  justify-content: center;
  height: 48px;
  gap: 6px;
`

export const RowTitleText = styled(Typography)`
  font-size: 11px;
  width: 30px;
`

export const RowTitleBigText = styled(Typography)`
  font-size: 12px;
`

export const RowControlWrapper = styled('div')`
  width: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 0 ${({ theme }) => theme.spacing(1.25)};
`

export const RowControlWithHidingWrapper = styled(RowControlWrapper)`
  height: 48px;
`

export const EventSelectorWrapper = styled('div')`
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.light};
  overflow: hidden;
`

export const EventName = styled(Typography)`
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
