import { ASSET_LIST_CACHE_KEY } from 'modules/asset/hooks'
import { MATCH_LIST_CACHE_KEY } from 'modules/match/hooks'
import { PLAYLIST_CACHE_KEY } from 'modules/playlist/hooks'
import { TICKER_LIST_CACHE_KEY } from 'modules/match-info/hooks'
import { TransferResourceBody } from '@sporttotal-tv/dip-coaching-client'

export const RESOURCE_CACHE_KEYS: Record<
  TransferResourceBody['resource'],
  string[]
> = {
  asset: [ASSET_LIST_CACHE_KEY, MATCH_LIST_CACHE_KEY, PLAYLIST_CACHE_KEY],
  playlist: [PLAYLIST_CACHE_KEY],
  highlight: [TICKER_LIST_CACHE_KEY],
}
