import { api } from 'modules/core/api'
import { transformApi } from 'modules/core/helpers'

import { AdminUserAccessService } from './user/admin-user-access.service'
import { AdminUserService } from './user/admin-user.service'
import { AdminOrganizationService } from './organization/admin-organization.service'

export const AdminApiService = {
  access: {
    list: transformApi(api.adminAccessGroup.listGroup),
    user: AdminUserAccessService,
  },

  user: AdminUserService,
  organization: AdminOrganizationService,
}
