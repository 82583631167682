import { FC, memo } from 'react'
// Icons
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg'
import { ReactComponent as UnlockIcon } from 'assets/icons/unlock.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as ClipsIcon } from 'assets/icons/clips.svg'
import { ReactComponent as LinkIcon } from 'assets/icons/link.svg'
import { ReactComponent as PlayerIcon } from 'assets/icons/player.svg'
import { ReactComponent as FilmReelicon } from 'assets/icons/film-reel.svg'
import { ReactComponent as RemoteIcon } from 'assets/icons/remote.svg'
import { ReactComponent as FlameIcon } from 'assets/icons/flame.svg'
import { ReactComponent as StatisticsIcon } from 'assets/icons/statistics.svg'
import { ReactComponent as FontsIcon } from 'assets/icons/fonts.svg'
import { ReactComponent as InputCursorIcon } from 'assets/icons/input-cursor.svg'
import { ReactComponent as PinIcon } from 'assets/icons/pin.svg'
import { ReactComponent as ZoomInIcon } from 'assets/icons/zoom-in.svg'
import { ReactComponent as ZoomOutIcon } from 'assets/icons/zoom-out.svg'
import { ReactComponent as NoteIcon } from 'assets/icons/note.svg'
import { ReactComponent as StartPathIcon } from 'assets/icons/start-path.svg'
import { ReactComponent as EndPathIcon } from 'assets/icons/end-path.svg'
import { ReactComponent as CalendarWeekIcon } from 'assets/icons/calendar-week.svg'

import { ReactComponent as DrawIcon } from 'assets/icons/draw.svg'
import { ReactComponent as ShieldIcon } from 'assets/icons/shield.svg'
import { ReactComponent as SkipEndIcon } from 'assets/icons/skip-end.svg'
import { ReactComponent as SkipStartIcon } from 'assets/icons/skip-start.svg'
import { ReactComponent as SkipBackwardIcon } from 'assets/icons/skip-backward.svg'
import { ReactComponent as SkipForwardIcon } from 'assets/icons/skip-forward.svg'
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg'
import { ReactComponent as PauseIcon } from 'assets/icons/pause.svg'
import { ReactComponent as StopIcon } from 'assets/icons/stop.svg'
import { ReactComponent as CutIcon } from 'assets/icons/cut.svg'
import { ReactComponent as SpeedometerIcon } from 'assets/icons/speedometer.svg'
import { ReactComponent as CardIcon } from 'assets/icons/card.svg'
import { ReactComponent as KickoffIcon } from 'assets/icons/kickoff.svg'
import { ReactComponent as StartOfGameIcon } from 'assets/icons/start-of-game.svg'
import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg'
import { ReactComponent as DisplayIcon } from 'assets/icons/display.svg'
import { ReactComponent as PanoramaIcon } from 'assets/icons/panorama.svg'
import { ReactComponent as AspectRationIcon } from 'assets/icons/aspect-ratio.svg'
import { ReactComponent as BroadCastIcon } from 'assets/icons/broadcast.svg'
import { ReactComponent as ChevronSmallDownIcon } from 'assets/icons/chevron-small-down.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { ReactComponent as LayersIcon } from 'assets/icons/layers.svg'
import { ReactComponent as OptionsIcon } from 'assets/icons/options.svg'
import { ReactComponent as VerticalMenuIcon } from 'assets/icons/vertical-menu.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg'
import { ReactComponent as BellIcon } from 'assets/icons/bell.svg'
import { ReactComponent as BookmarkIcon } from 'assets/icons/bookmark.svg'
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg'
import { ReactComponent as VolumeOffIcon } from 'assets/icons/volume-off.svg'
import { ReactComponent as VolumeUpIcon } from 'assets/icons/volume-up.svg'
import { ReactComponent as PlaylistFilledIcon } from 'assets/icons/playlist-filled.svg'
import { ReactComponent as CircleIcon } from 'assets/icons/circle.svg'
import { ReactComponent as PlusCircleIcon } from 'assets/icons/plus-circle.svg'
import { ReactComponent as CloseCircleIcon } from 'assets/icons/cancel-circle.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg'
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg'
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg'
import { ReactComponent as PendingIcon } from 'assets/icons/pending.svg'
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg'
import { ReactComponent as PersonFilledIcon } from 'assets/icons/person-fill.svg'
import { ReactComponent as GeolocationIcon } from 'assets/icons/geolocation.svg'
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg'
import { ReactComponent as ClipboardIcon } from 'assets/icons/clipboard.svg'
import { ReactComponent as ChevronBarLeftIcon } from 'assets/icons/chevron-bar-left.svg'
import { ReactComponent as ChatIcon } from 'assets/icons/chat.svg'
import { ReactComponent as QuestionSquareIcon } from 'assets/icons/question-square.svg'
import { ReactComponent as QuestionCircleIcon } from 'assets/icons/question-circle.svg'
import { ReactComponent as LanguageIcon } from 'assets/icons/languages.svg'
import { ReactComponent as FullscreenExitIcon } from 'assets/icons/fullscreen-exit.svg'
import { ReactComponent as FullscreenIcon } from 'assets/icons/fullscreen.svg'
import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-circle.svg'
import { ReactComponent as DangerCircleIcon } from 'assets/icons/danger-circle.svg'
import { ReactComponent as SuspendIcon } from 'assets/icons/suspend.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as PlayersIcon } from 'assets/icons/players.svg'
import { ReactComponent as ContractIcon } from 'assets/icons/contract.svg'
import { ReactComponent as InjuryIcon } from 'assets/icons/injury.svg'
import { ReactComponent as StatsIcon } from 'assets/icons/stats.svg'
import { ReactComponent as StatusIcon } from 'assets/icons/status.svg'
import { ReactComponent as TransferIcon } from 'assets/icons/transfer.svg'
import { ReactComponent as FieldFootballIcon } from 'assets/icons/field-football.svg'
import { ReactComponent as FieldVolleyballIcon } from 'assets/icons/field-volleyball.svg'
import { ReactComponent as PositionIcon } from 'assets/icons/position.svg'
import { ReactComponent as PositionSelectedIcon } from 'assets/icons/position-selected.svg'
import { ReactComponent as CameraIcon } from 'assets/icons/camera.svg'
import { ReactComponent as StopwatchIcon } from 'assets/icons/stopwatch.svg'

// Events Icons
// Football
import { ReactComponent as CornerBallIcon } from 'assets/icons/events/corner-ball.svg'
import { ReactComponent as FoulIcon } from 'assets/icons/events/foul.svg'
import { ReactComponent as FreeKickIcon } from 'assets/icons/events/free-kick.svg'
import { ReactComponent as GoalIcon } from 'assets/icons/events/goal.svg'
import { ReactComponent as GoalSaveIcon } from 'assets/icons/events/goal-save.svg'
import { ReactComponent as OffsideIcon } from 'assets/icons/events/offside.svg'
import { ReactComponent as PenaltyIcon } from 'assets/icons/events/penalty.svg'
import { ReactComponent as RedCardIcon } from 'assets/icons/events/red-card.svg'
import { ReactComponent as ShootIcon } from 'assets/icons/events/shoot.svg'
import { ReactComponent as SubsituteIcon } from 'assets/icons/events/subsitute.svg'
import { ReactComponent as YellowCardIcon } from 'assets/icons/events/yellow-card.svg'
import { ReactComponent as DirectKickIcon } from 'assets/icons/events/direct-kick.svg'
import { ReactComponent as InDirectKickIcon } from 'assets/icons/events/indirect-kick.svg'
import { ReactComponent as AttThrowIcon } from 'assets/icons/events/att-throw.svg'
import { ReactComponent as ConterOffIcon } from 'assets/icons/events/counter-off.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/events/cross.svg'
import { ReactComponent as StartOfHalfIcon } from 'assets/icons/events/start-of-half.svg'
import { ReactComponent as EndOfHalfIcon } from 'assets/icons/events/end-of-half.svg'
import { ReactComponent as KickOffIcon } from 'assets/icons/events/kick-off-2.svg'
import { ReactComponent as GoalKickIcon } from 'assets/icons/events/goal-kick.svg'
import { ReactComponent as TrainingStartIcon } from 'assets/icons/events/start-training-2.svg'
import { ReactComponent as TrainingEndIcon } from 'assets/icons/events/end-training-2.svg'

// Volleyball
import { ReactComponent as BallTouchNetIcon } from 'assets/icons/events/volleyball/ball-touch-net.svg'
import { ReactComponent as PlayerTouchBallIcon } from 'assets/icons/events/volleyball/player-touch-ball.svg'
import { ReactComponent as ChangeServeIcon } from 'assets/icons/events/volleyball/change-serve.svg'
import { ReactComponent as GameEndIcon } from 'assets/icons/events/volleyball/game-end.svg'
import { ReactComponent as GameStartIcon } from 'assets/icons/events/volleyball/game-start.svg'
import { ReactComponent as HighlightIcon } from 'assets/icons/events/volleyball/highlight.svg'
import { ReactComponent as HitInPitchIcon } from 'assets/icons/events/volleyball/hit-in-pitch.svg'
import { ReactComponent as HitOutPitchIcon } from 'assets/icons/events/volleyball/hit-out-pitch.svg'
import { ReactComponent as LiberoInIcon } from 'assets/icons/events/volleyball/libero-in.svg'
import { ReactComponent as LiberoOutIcon } from 'assets/icons/events/volleyball/libero-out.svg'
import { ReactComponent as LiberoSwitchIcon } from 'assets/icons/events/volleyball/libero-switch.svg'
import { ReactComponent as PassAndBlockIcon } from 'assets/icons/events/volleyball/pass-and-block.svg'
import { ReactComponent as PassAndHitIcon } from 'assets/icons/events/volleyball/pass-and-hit.svg'
import { ReactComponent as ServeIcon } from 'assets/icons/events/volleyball/serve.svg'
import { ReactComponent as ScoreIcon } from 'assets/icons/events/volleyball/score.svg'
import { ReactComponent as ServeFootFoulIcon } from 'assets/icons/events/volleyball/serve-foot-foul.svg'
import { ReactComponent as ServeReturnIcon } from 'assets/icons/events/volleyball/serve-return.svg'
import { ReactComponent as ServeRotationIcon } from 'assets/icons/events/volleyball/serve-rotation.svg'
import { ReactComponent as SetEndOneIcon } from 'assets/icons/events/volleyball/set-end-1.svg'
import { ReactComponent as SetEndTwoIcon } from 'assets/icons/events/volleyball/set-end-2.svg'
import { ReactComponent as SetEndThreeIcon } from 'assets/icons/events/volleyball/set-end-3.svg'
import { ReactComponent as SetEndFourIcon } from 'assets/icons/events/volleyball/set-end-4.svg'
import { ReactComponent as SetEndFiveIcon } from 'assets/icons/events/volleyball/set-end-5.svg'
import { ReactComponent as SetStartOneIcon } from 'assets/icons/events/volleyball/set-start-1.svg'
import { ReactComponent as SetStartTwoIcon } from 'assets/icons/events/volleyball/set-start-2.svg'
import { ReactComponent as SetStartThreeIcon } from 'assets/icons/events/volleyball/set-start-3.svg'
import { ReactComponent as SetStartFourIcon } from 'assets/icons/events/volleyball/set-start-4.svg'
import { ReactComponent as SetStartFiveIcon } from 'assets/icons/events/volleyball/set-start-5.svg'

// Telestration Icons
import { ReactComponent as ArrowIcon } from 'assets/icons/telestration/arrow.svg'
import { ReactComponent as EraserIcon } from 'assets/icons/telestration/eraser.svg'
import { ReactComponent as FreeLineIcon } from 'assets/icons/telestration/free-line.svg'
import { ReactComponent as PencilIcon } from 'assets/icons/telestration/pencil.svg'
import { ReactComponent as SingleSelectIcon } from 'assets/icons/telestration/single-select.svg'
import { ReactComponent as Rectangle } from 'assets/icons/telestration/rectangle.svg'
import { ReactComponent as AwayTshirt } from 'assets/icons/away-tshirt.svg'
import { ReactComponent as HomeTshirt } from 'assets/icons/home-tshirt.svg'
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg'
import { ReactComponent as MapMarkerIcon } from 'assets/icons/map-marker.svg'

// MUI Icons
import AssetsIcon from '@mui/icons-material/VideoLibraryOutlined'
import PlaylistIcon from '@mui/icons-material/PlaylistPlay'
import LabelIcon from '@mui/icons-material/LabelOutlined'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlotOutlined'
import TimelineIcon from '@mui/icons-material/ViewTimelineOutlined'

import { IconProps, IconsMap } from './Icon.interface'

const icons: IconsMap = {
  lock: LockIcon,
  unlock: UnlockIcon,
  close: CloseIcon,
  clips: ClipsIcon,
  assets: AssetsIcon,
  controls: RemoteIcon,
  link: LinkIcon,
  minimap: ScatterPlotIcon,
  player: PlayerIcon,
  statistics: StatisticsIcon,
  telestration: DrawIcon,
  shield: ShieldIcon,
  flame: FlameIcon,
  fonts: FontsIcon,
  'input-cursor': InputCursorIcon,
  pin: PinIcon,
  'zoom-in': ZoomInIcon,
  'zoom-out': ZoomOutIcon,
  'skip-end': SkipEndIcon,
  'skip-start': SkipStartIcon,
  'skip-backward': SkipBackwardIcon,
  'skip-forward': SkipForwardIcon,
  play: PlayIcon,
  pause: PauseIcon,
  stop: StopIcon,
  cut: CutIcon,
  speedometer: SpeedometerIcon,
  card: CardIcon,
  kickoff: KickoffIcon,
  'start-of-game': StartOfGameIcon,
  'chevron-up': ChevronUpIcon,
  display: DisplayIcon,
  panorama: PanoramaIcon,
  'aspect-ratio': AspectRationIcon,
  broadcast: BroadCastIcon,
  'chevron-small-down': ChevronSmallDownIcon,
  info: InfoIcon,
  plus: PlusIcon,
  save: SaveIcon,
  check: CheckIcon,
  eye: EyeIcon,
  delete: DeleteIcon,
  layers: LayersIcon,
  options: OptionsIcon,
  'vertical-menu': VerticalMenuIcon,
  trash: TrashIcon,
  bell: BellIcon,
  share: ShareIcon,
  bookmark: BookmarkIcon,
  timeline: TimelineIcon,
  'volume-off': VolumeOffIcon,
  'volume-up': VolumeUpIcon,
  playlist: PlaylistIcon,
  'playlist-filled': PlaylistFilledIcon,
  note: NoteIcon,
  'end-path': EndPathIcon,
  'start-path': StartPathIcon,
  'calendar-week': CalendarWeekIcon,
  'plus-circle': PlusCircleIcon,
  'close-circle': CloseCircleIcon,
  download: DownloadIcon,
  profile: ProfileIcon,
  settings: SettingsIcon,
  logout: LogoutIcon,
  error: ErrorIcon,
  pending: PendingIcon,
  success: SuccessIcon,
  'person-filled': PersonFilledIcon,
  geolocation: GeolocationIcon,
  person: PersonIcon,
  clipboard: ClipboardIcon,
  'chevron-bar-left': ChevronBarLeftIcon,
  chat: ChatIcon,
  'question-square': QuestionSquareIcon,
  'question-circle': QuestionCircleIcon,
  'fullscreen-exit': FullscreenExitIcon,
  fullscreen: FullscreenIcon,
  languages: LanguageIcon,
  'check-circle': CheckCircleIcon,
  'danger-circle': DangerCircleIcon,
  suspend: SuspendIcon,
  search: SearchIcon,
  contract: ContractIcon,
  injury: InjuryIcon,
  stats: StatsIcon,
  status: StatusIcon,
  transfer: TransferIcon,
  'field-football': FieldFootballIcon,
  'field-volleyball': FieldVolleyballIcon,
  position: PositionIcon,
  'position-selected': PositionSelectedIcon,
  label: LabelIcon,
  'film-reel': FilmReelicon,
  'map-marker': MapMarkerIcon,
  // Event Icons
  // Football
  'corner-ball': CornerBallIcon,
  foul: FoulIcon,
  'free-kick': FreeKickIcon,
  goal: GoalIcon,
  'goal-save': GoalSaveIcon,
  offside: OffsideIcon,
  penalty: PenaltyIcon,
  'red-card': RedCardIcon,
  shoot: ShootIcon,
  subsitute: SubsituteIcon,
  'yellow-card': YellowCardIcon,
  'direct-kick': DirectKickIcon,
  'indirect-kick': InDirectKickIcon,
  'att-throw': AttThrowIcon,
  'counter-off': ConterOffIcon,
  cross: CrossIcon,
  players: PlayersIcon,
  'start-of-half': StartOfHalfIcon,
  'end-of-half': EndOfHalfIcon,
  'kick-off': KickOffIcon,
  'goal-kick': GoalKickIcon,
  'training-start': TrainingStartIcon,
  'training-end': TrainingEndIcon,
  // Volleyball
  'change-serve': ChangeServeIcon,
  'game-end': GameEndIcon,
  'game-start': GameStartIcon,
  'ball-touch-net': BallTouchNetIcon,
  highlight: HighlightIcon,
  'hit-in-pitch': HitInPitchIcon,
  'hit-out-pitch': HitOutPitchIcon,
  'libero-in': LiberoInIcon,
  'libero-out': LiberoOutIcon,
  'libero-switch': LiberoSwitchIcon,
  'pass-and-block': PassAndBlockIcon,
  'pass-and-hit': PassAndHitIcon,
  'player-touch-ball': PlayerTouchBallIcon,
  score: ScoreIcon,
  'serve-foot-foul': ServeFootFoulIcon,
  'serve-return': ServeReturnIcon,
  'serve-rotation': ServeRotationIcon,
  serve: ServeIcon,
  'set-end-1': SetEndOneIcon,
  'set-end-2': SetEndTwoIcon,
  'set-end-3': SetEndThreeIcon,
  'set-end-4': SetEndFourIcon,
  'set-end-5': SetEndFiveIcon,
  'set-start-1': SetStartOneIcon,
  'set-start-2': SetStartTwoIcon,
  'set-start-3': SetStartThreeIcon,
  'set-start-4': SetStartFourIcon,
  'set-start-5': SetStartFiveIcon,
  // Telestration Icons
  arrow: ArrowIcon,
  eraser: EraserIcon,
  'free-line': FreeLineIcon,
  rectangle: Rectangle,
  circle: CircleIcon,
  pencil: PencilIcon,
  'single-select': SingleSelectIcon,
  't-away': AwayTshirt,
  't-home': HomeTshirt,
  time: TimeIcon,
  camera: CameraIcon,
  stopwatch: StopwatchIcon,
}

export const Icon: FC<IconProps> = memo(({ name, ...props }) => {
  const IconElement = icons[name]

  if (!IconElement) throw new Error(`Icon with name: "${name}" does not exist.`)

  return <IconElement name={name} fill='#96989b' {...(props as any)} />
})
