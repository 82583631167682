import { TokenService } from 'services/Token.service'
import { api } from 'modules/core/api'
import { APP_SSO_AUTH_URL } from 'modules/auth/constants'

export const AuthApiService = {
  getAccessToken: async (code: string | null) => {
    if (!code) {
      return
    }
    return api.auth.verifyCode({
      code,
      redirect_uri: window.location.origin,
    })
  },

  refreshToken: () => {
    const refreshToken = TokenService.getInstance().getRefreshToken()
    if (!refreshToken.value) {
      throw new Error('No refresh token')
    }
    return api.auth.refreshToken({
      refresh_token: refreshToken.value ?? undefined,
    })
  },

  verifyToken: () => {
    const token = TokenService.getInstance().getToken()
    return fetch(
      `${APP_SSO_AUTH_URL}/oauth/token/info?access_token=${token.value}`
    )
  },
}
