import { useMutation, useQueryClient } from 'react-query'
import { CreateHighlightBody } from '@sporttotal-tv/dip-coaching-client'
import { ApiService } from 'services/Api.service'
import { TICKER_LIST_CACHE_KEY } from './useTickerList'
import { MATCH_RETRIEVE_CACHE_KEY } from 'modules/match/hooks'

export const TICKER_CREATE_CACHE_KEY = 'ticker-create'

export const useTickerCreate = () => {
  const queryClient = useQueryClient()

  return useMutation(
    TICKER_CREATE_CACHE_KEY,
    (params: CreateHighlightBody) => ApiService.matchInfo.tickerCreate(params),
    {
      onSettled: () => {
        const invalidateOpts = { exact: false }

        queryClient.invalidateQueries(TICKER_LIST_CACHE_KEY, invalidateOpts)
        queryClient.invalidateQueries(MATCH_RETRIEVE_CACHE_KEY, invalidateOpts)
      },
    }
  )
}
