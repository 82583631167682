import { Fragment } from 'react'
import { Navigate } from 'react-router-dom'
// Pages
import { Landing } from 'pages/Landing'
import { Redirecting } from 'pages/Redirecting'
import { PlaylistPage } from 'pages/Playlist'
import { Profile } from 'pages/Profile'
import { Privacy } from 'pages/Privacy'
import { Terms } from 'pages/Terms'
import { Cookies } from 'pages/Cookies'
import { EventAnalyser } from 'pages/EventAnalyser'
import { Users } from 'pages/Users'
import { AdminUserProfile } from 'pages/AdminUserProfile'
import { Invites } from 'pages/Invites'
import { Organizations } from 'pages/Organizations'
import { OrganizationUsers } from 'pages/OrganizationUsers'
import { OrganizationInvites } from 'pages/OrganizationInvites'
import { AdminOrganizations } from 'pages/AdminOrganizations'
import { AdminOrganizationView } from 'pages/AdminOrganizationOverview'
import { Team } from 'pages/Team'
import { Dev } from 'pages/Dev'
import { Calendar } from 'pages/Calendar'
import { Subscription } from 'pages/Subscription'
import { PaymentStatus } from 'pages/PaymentStatus'
import { NotFound } from 'pages/NotFound'
import { ProfileSettings } from 'modules/user/components/ProfileSettings'
// Constants
import { routeNames } from 'modules/core/constants'
import { userRoles } from 'modules/user/constants'
// Types
import type { Route } from 'modules/core/types'
// Layouts
import {
  ProfilePageLayout,
  HelpPageLayout,
  EventAnalyserPageLayout,
  PlaylistPageLayout,
  AdminPageLayout,
  TeamPageLayout,
  EmptyPageLayout,
  OrganizationPageLayout,
  OrganizationAdminOverviewPageLayout,
} from 'modules/core/layouts'

export const routes: Route[] = [
  {
    layout: EventAnalyserPageLayout,
    layoutPath: routeNames.analysis.relative,
    routes: [
      {
        path: '/:tab',
        element: <EventAnalyser />,
      },
      {
        path: '/:tab/*',
        element: <Navigate to='dashboard' replace />,
      },
      {
        path: '/:tab/*/*',
        element: <Navigate to='dashboard' replace />,
      },
    ],
  },
  {
    layout: PlaylistPageLayout,
    layoutPath: routeNames.playlist.relative,
    routes: [
      {
        path: '/:id',
        element: <PlaylistPage />,
      },
      {
        path: '/:id/*/*',
        element: <Navigate to='some' replace />,
      },
    ],
  },
  {
    layout: ProfilePageLayout,
    layoutPath: routeNames.profile.relative,
    routes: [
      {
        path: '/view',
        element: <Profile />,
      },
      {
        path: '/settings',
        element: <ProfileSettings />,
      },
      {
        path: '*',
        element: <Navigate to='view' replace />,
      },
    ],
  },
  {
    layout: HelpPageLayout,
    layoutPath: routeNames.help.relative,
    routes: [
      {
        path: '/privacy',
        element: <Privacy />,
      },
      {
        path: '/cookie',
        element: <Cookies />,
      },
      {
        path: '/terms',
        element: <Terms />,
      },
      {
        path: '*',
        element: <Navigate to='privacy' replace />,
      },
    ],
  },
  {
    layout: AdminPageLayout,
    layoutPath: routeNames.admin.relative,
    requiredRoles: [userRoles.ADMIN, userRoles.ROOT],
    routes: [
      {
        path: '/users',
        element: <Users />,
      },
      {
        path: '/user/:id',
        element: <AdminUserProfile />,
      },
      {
        path: '/invites',
        element: <Invites />,
      },
      {
        path: '/dev',
        element: <Dev />,
      },
      {
        path: '/organizations',
        element: <AdminOrganizations />,
      },
      {
        path: '/organization/:id',
        element: <AdminOrganizationView />,
      },
      {
        path: '*',
        element: <Navigate to='users' replace />,
      },
    ],
  },
  {
    layout: OrganizationPageLayout,
    layoutPath: routeNames.organization.relative,
    requiredRoles: [userRoles.ADMIN, userRoles.ROOT, userRoles.COACH],
    routes: [
      {
        path: '/:id/users',
        element: <OrganizationUsers />,
        layout: OrganizationAdminOverviewPageLayout,
      },
      {
        path: '/:id/invites',
        element: <OrganizationInvites />,
        layout: OrganizationAdminOverviewPageLayout,
      },
      {
        path: '/',
        element: <Organizations />,
      },
    ],
  },
  {
    layout: Fragment,
    layoutPath: routeNames.main.relative,
    routes: [
      {
        path: '/redirecting',
        element: <Redirecting />,
        restricted: false,
      },
      {
        path: '/',
        element: <Landing />,
        restricted: false,
      },
      {
        path: '*',
        element: <NotFound />,
        restricted: false,
      },
      {
        path: '/privacy',
        element: <Privacy />,
        restricted: false,
      },
      {
        path: '/cookie',
        element: <Cookies />,
        restricted: false,
      },
      {
        path: '/terms',
        element: <Terms />,
        restricted: false,
      },
    ],
  },
  {
    layout: TeamPageLayout,
    layoutPath: routeNames.team.relative,
    routes: [
      {
        path: '/:tab',
        element: <Team />,
      },
      {
        path: '/:tab/*',
        element: <Navigate to='dashboard' replace />,
      },
      {
        path: '/:tab/*/*',
        element: <Navigate to='dashboard' replace />,
      },
    ],
  },
  {
    layout: EmptyPageLayout,
    layoutPath: routeNames.calendar.relative,
    requiredTier: 'advanced',
    routes: [
      {
        path: '/',
        element: <Calendar />,
      },
      {
        path: '*',
        element: <Navigate to='' replace />,
      },
    ],
  },
  {
    layout: Fragment,
    layoutPath: routeNames.subscription.relative,
    routes: [
      {
        path: '/payment-status',
        element: <PaymentStatus />,
      },
      { path: '/', element: <Subscription /> },
    ],
  },
]
