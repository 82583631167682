import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { TICKER_LIST_CACHE_KEY } from './useTickerList'
import { MATCH_RETRIEVE_CACHE_KEY } from 'modules/match/hooks'

export const TICKER_DELETE_CACHE_KEY = 'ticker-delete'

export const useTickerDelete = () => {
  const queryClient = useQueryClient()

  return useMutation(
    TICKER_DELETE_CACHE_KEY,
    (id: string) => ApiService.matchInfo.tickerDelete(id),
    {
      onSettled: () => {
        queryClient.invalidateQueries(TICKER_LIST_CACHE_KEY)
        queryClient.invalidateQueries(MATCH_RETRIEVE_CACHE_KEY)
      },
    }
  )
}
