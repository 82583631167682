import { ASSET_LIST_CACHE_KEY } from 'modules/asset/hooks'
import { FRAME_LIST_CACHE_KEY } from 'modules/frame/hooks'
import { TICKER_LIST_CACHE_KEY } from 'modules/match-info/hooks'
import { MATCH_LIST_CACHE_KEY } from 'modules/match/hooks'
import { PLAYLIST_CACHE_KEY } from 'modules/playlist/hooks'
import { TELESTRATION_GROUPS_LIST_CACHE_KEY } from 'modules/telestration/hooks'

export const USER_CONTENT_QUERY_CACHE_KEYS = [
  PLAYLIST_CACHE_KEY,
  MATCH_LIST_CACHE_KEY,
  ASSET_LIST_CACHE_KEY,
  TELESTRATION_GROUPS_LIST_CACHE_KEY,
  FRAME_LIST_CACHE_KEY,
  TICKER_LIST_CACHE_KEY,
]
