import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
  MouseEvent,
  ChangeEvent,
} from 'react'
// Hooks
import { useResizeObserver } from 'modules/core/hooks'
import { useMatch } from 'modules/match/hooks'
import { useSchedule } from 'modules/schedule/hooks'
import { useCameraFetch, useCameraUpdate } from 'modules/camera/hooks'
// Components
import { Rnd } from 'react-rnd'
import { ToggleSwitch } from 'modules/core/components'
import { CameraViewport } from 'modules/camera/components'
// Utils
import { parseSystemRef } from 'modules/core/helpers'
import {
  getMidpoint,
  getCameraTarget,
  getViewTopLeft,
} from 'modules/camera/utils'
// Constants
import {
  DEFAULT_LIMIT_ZOOM_HIGHER,
  DEFAULT_LIMIT_ZOOM_LOWER,
} from 'modules/camera/constants'
// Types
import { DraggableData, DraggableEvent } from 'react-draggable'
import { CameraUpdatePayload } from '@sporttotal-tv/dip-coaching-client'
import { Point } from 'modules/camera/types'

// Styled
import {
  Root,
  ControlsHeader,
  PanoramicViewport,
  ViewPresetButtonGroup,
  ViewPresetButton,
  ToggleSwitchContainer,
} from './CameraPositionControl.styled'

export const CameraPositionControl = () => {
  const { match } = useMatch()

  const { identifier } = useMemo(() => {
    if (!match) return { identifier: undefined }
    return parseSystemRef(match.system_ref)
  }, [match])

  const { data: schedule } = useSchedule(identifier)
  const { data: cameraData } = useCameraFetch(schedule?.thing?.name)
  const { mutate } = useCameraUpdate(schedule?.thing?.name)

  const cameraViewportRef = useRef<Rnd>(null)
  const panoramicViewportRef = useRef<HTMLDivElement>(null)
  const { width: panoramicViewportWidth, height: panoramicViewportHeight } =
    useResizeObserver(panoramicViewportRef)

  const isManualControlEnabled =
    cameraData?.autoproduction_configuration?.enable_manual_control ?? false

  const cameraTarget = useMemo<Point>(() => {
    if (!cameraData?.autoproduction_configuration) return { x: 0, y: 0 }

    const { target_x: x = 0, target_y: y = 0 } =
      cameraData.autoproduction_configuration
    return { x, y }
  }, [cameraData])

  // const cameraZoom = useMemo<ZoomLimits>(() => {
  //   if (!cameraData?.autoproduction_configuration)
  //     return { limit_zoom_higher: 100, limit_zoom_lower: 0.1 }
  //   const { limit_zoom_higher = 100, limit_zoom_lower = 0.1 } =
  //     cameraData.autoproduction_configuration
  //   return { limit_zoom_higher, limit_zoom_lower }
  // }, [cameraData])

  const [isDragging, setIsDragging] = useState(false)
  const [statusMessage, setStatusMessage] = useState('')

  const handleControlMode = (event: ChangeEvent<HTMLInputElement>) => {
    if (!cameraData) return

    const autoproductionConfiguration: CameraUpdatePayload['autoproduction_configuration'] =
      {
        enable_manual_control: event.target.checked,
      }

    if (!autoproductionConfiguration.enable_manual_control) {
      autoproductionConfiguration.limit_zoom_higher = DEFAULT_LIMIT_ZOOM_HIGHER
      autoproductionConfiguration.limit_zoom_lower = DEFAULT_LIMIT_ZOOM_LOWER
    }

    mutate({
      autoproduction_configuration: autoproductionConfiguration,
    })
  }

  const handleOnDragStart = () => setIsDragging(true)

  const handleViewDragStop = useCallback(
    (_: DraggableEvent, data: DraggableData) => {
      const cameraViewport = cameraViewportRef.current

      setIsDragging(false)

      if (!cameraViewport) return
      if (!panoramicViewportRef.current) return
      if (!isManualControlEnabled) return

      setStatusMessage('Sending target to camera 🔄')

      const { width: cameraViewportWidth, height: cameraViewportHeight } =
        cameraViewport.resizable.size

      const cameraViewportMidpoint = getMidpoint(data, {
        width: cameraViewportWidth,
        height: cameraViewportHeight,
      })

      const cameraTarget = getCameraTarget(cameraViewportMidpoint, {
        width: panoramicViewportWidth,
        height: panoramicViewportHeight,
      })

      const { x: target_x, y: target_y } = cameraTarget

      mutate(
        {
          autoproduction_configuration: { target_x, target_y },
        },
        {
          onSuccess: () => setStatusMessage('Target recieved ✅'),
          onError: () => setStatusMessage('Failed ❌'),
        }
      )
    },
    [
      mutate,
      cameraViewportRef,
      isManualControlEnabled,
      panoramicViewportWidth,
      panoramicViewportHeight,
    ]
  )

  const handleViewPresetButton = (event: MouseEvent<HTMLButtonElement>) => {
    if (!cameraViewportRef.current) return
    if (!panoramicViewportRef.current) return
    if (!isManualControlEnabled) return

    setStatusMessage('Sending target to camera 🔄')

    const {
      offsetWidth: buttonWidth,
      offsetHeight: buttonHeight,
      offsetLeft: pointX,
      offsetTop: pointY,
    } = event.currentTarget

    const buttonSize = { width: buttonWidth, height: buttonHeight }
    const buttonPoint = { x: pointX, y: pointY }

    const buttonMidpoint = getMidpoint(buttonPoint, buttonSize)
    const cameraTarget = getCameraTarget(buttonMidpoint, {
      width: panoramicViewportWidth,
      height: panoramicViewportHeight,
    })

    const { x: target_x, y: target_y } = cameraTarget

    mutate(
      {
        autoproduction_configuration: { target_x, target_y },
      },
      {
        onSuccess: () => setStatusMessage('Target recieved ✅'),
        onError: () => setStatusMessage('Failed ❌'),
      }
    )
  }

  // const handleResizeStop = (
  //   event: DraggableEvent,
  //   [dir, elementRef, delta, position]: DraggableData[]
  // ) => {
  //   if (!viewRef.current) return
  //   if (!viewContainerRef.current) return
  //   setStatusMessage('Sending target to camera 🔄')

  //   const { width: offsetWidth, height: offsetHeight } =
  //     viewRef.current.resizable.size

  //   const viewMidpoint = getMidpoint(position, { offsetWidth, offsetHeight })

  //   const { x: target_x, y: target_y } = getCameraTarget(
  //     viewMidpoint,
  //     viewContainerRef.current
  //   )

  //   if (isManualControlEnabled)
  //     mutate(
  //       {
  //         autoproduction_configuration: { target_x, target_y },
  //       },
  //       {
  //         onSuccess: () => setStatusMessage('Target recieved ✅'),
  //         onError: () => {
  //           setStatusMessage('Failed ❌')
  //         },
  //       }
  //     )
  // }

  useEffect(() => {
    const cameraViewport = cameraViewportRef.current

    if (!cameraData) return
    if (!cameraViewport) return
    if (!panoramicViewportRef.current) return

    const { width, height } = cameraViewport.resizable.size

    const panoramicViewport = {
      width: panoramicViewportWidth,
      height: panoramicViewportHeight,
    }

    const viewTopLeft = getViewTopLeft(
      cameraTarget,
      { width, height },
      panoramicViewport
    )

    cameraViewportRef.current.updatePosition(viewTopLeft)
  }, [cameraData])

  return (
    <Root>
      <ControlsHeader>
        <ToggleSwitchContainer>
          <ToggleSwitch
            onChange={handleControlMode}
            checked={isManualControlEnabled}
          />
          Manual Control
        </ToggleSwitchContainer>
        <div>{statusMessage}</div>
      </ControlsHeader>

      <PanoramicViewport ref={panoramicViewportRef}>
        <ViewPresetButtonGroup
          variant='text'
          fullWidth={true}
          disabled={!isManualControlEnabled || isDragging}
        >
          <ViewPresetButton onClick={handleViewPresetButton}>
            Left
          </ViewPresetButton>
          <ViewPresetButton onClick={handleViewPresetButton}>
            Center
          </ViewPresetButton>
          <ViewPresetButton onClick={handleViewPresetButton}>
            Right
          </ViewPresetButton>
        </ViewPresetButtonGroup>
        <CameraViewport
          ref={cameraViewportRef}
          onDragStart={handleOnDragStart}
          onDragStop={handleViewDragStop}
          disableDragging={!isManualControlEnabled}
          // onResizeStop={handleResizeStop}
          // enableResizing={{ bottomRight: isManualControlEnabled }}
        />
      </PanoramicViewport>
    </Root>
  )
}
