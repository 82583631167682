import { useQuery } from 'react-query'
import { useQueryRetry } from 'modules/core/hooks'
import { ApiService } from 'services/Api.service'
import { EventListParams } from '@sporttotal-tv/dip-coaching-client'

import { DEFAULT_PAGE_LIMIT } from 'modules/event/constants'

export const EVENT_LIST_CACHE_KEY = 'event-list'

export const useEventList = (options?: EventListParams) => {
  const handleQueryRetry = useQueryRetry()

  const queryOptions: EventListParams = {
    page_limit: 100,
    sort_by: 'type',
    ...options,
  }

  const enabled =
    options && 'sport_id' in options ? options.sport_id !== undefined : true

  return useQuery(
    [EVENT_LIST_CACHE_KEY, options],
    () => ApiService.event.list(queryOptions),
    {
      staleTime: Infinity,
      retry: handleQueryRetry,
      enabled,
    }
  )
}
