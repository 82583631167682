import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
// Context
import { ComponentInfoContext } from 'modules/generic/context'
import {
  defaultIconName,
  eventIndexToIconDictionary,
} from 'modules/core/constants'
// Hooks
import { useTickerDelete, useTickerCreate } from 'modules/match-info/hooks'
import { useMatch, useMatchHighlights } from 'modules/match/hooks'
import { useOrganizationContext } from 'modules/organization/hooks'
import {
  useAnalyserPlayer,
  useAnalyserPlayerTimestamp,
} from 'modules/analyser/hooks'
import { useEventList } from 'modules/event/hooks'
import { useServerTranslatedProperty } from 'modules/core/hooks'
// Components
import { Menu, MenuItem, ResourceControls } from 'modules/core/components'
import { ToolbarControls, ToolbarHeader } from 'modules/generic/components'
import { ToolbarDivider } from 'modules/generic/styled'
import { VideoPlayerSelector } from 'modules/video-player/components'
import { LayoutItemToolbarContainer } from 'modules/layout/components/LayoutItemToolbar/LayoutItemToolbar.styled'
import { TransferResourceModal } from 'modules/resource/components'
// Utils
import { convertSecondsToTime } from 'modules/core/utils'
// Constants
import {
  OMITTED_TICKER_EVENT_TYPES,
  TICKER_COLORS,
} from 'modules/match-info/constants'
// Types
import { Highlight, Organization } from '@sporttotal-tv/dip-coaching-client'

import {
  Root,
  ToolbarContainer,
  Divider,
  Tab,
  ButtonsContainer,
  StyledButton,
  ActionIcon,
  ActionText,
  Content,
  ListContainer,
  TickerElement,
  TickerTime,
  TickerWrapper,
  TickerText,
  Label,
  InputText,
  DelayWrapper,
} from './Ticker.styled'

export const Ticker = () => {
  const { componentId, layoutIndex } = useContext(ComponentInfoContext)
  const [activeView, setActiveView] = useState<'add' | 'list'>('add')
  const [delay, setDelay] = useState(3)
  const tServer = useServerTranslatedProperty()
  const { t } = useTranslation('components', { keyPrefix: 'ticker' })
  const playerTimestamp = useAnalyserPlayerTimestamp()
  const player = useAnalyserPlayer()

  const { activeOrganizationId, organizations } = useOrganizationContext()
  const { match } = useMatch()
  const { ticker } = useMatchHighlights()

  const tickerCreate = useTickerCreate()
  const tickerDelete = useTickerDelete()

  const eventList = useEventList({ sport_id: match?.sport?.id })

  const [transferModalAnchor, setTransferModalAnchor] = useState<HTMLElement>()
  const [selectedTicker, setSelectedTicker] = useState<Highlight>()
  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>()

  const sortedTickers = useMemo(
    () => ticker.sort((a, b) => (a.start_time > b.start_time ? 1 : -1)),
    [ticker]
  )

  const canTransfer = useMemo(
    () => organizations.length && !activeOrganizationId,
    [activeOrganizationId, organizations.length]
  )

  const addManualHighlight = (eventId: string) => () => {
    if (match) {
      tickerCreate.mutate({
        accuracy: 1,
        start_time: playerTimestamp - delay,
        duration: 4,
        event_id: eventId,
        match_id: match.id,
        is_manual: true,
      })
    }
  }

  const deleteTicker = (tickerId: string) => () => {
    tickerDelete.mutate(tickerId)
  }

  const handleDelayChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDelay(Number(event.target.value))
    },
    []
  )

  const handleEventClick = useCallback(
    (time: number) => () => {
      if (player?.currentTime) player.currentTime = time
    },
    [player]
  )

  const handleTransferOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>, ticker: Highlight) => {
      setTransferModalAnchor(event.target as HTMLElement)
      setSelectedTicker(ticker)
    },
    []
  )

  const handleTransferClose = useCallback(() => {
    setTransferModalAnchor(undefined)
    setSelectedOrganization(undefined)
  }, [])

  return (
    <>
      <Root>
        <LayoutItemToolbarContainer>
          <ToolbarHeader layoutId={componentId} layoutIndex={layoutIndex}>
            <ToolbarDivider />
            <VideoPlayerSelector />
          </ToolbarHeader>
          <ToolbarContainer>
            <Tab
              active={activeView === 'add'}
              onClick={() => setActiveView('add')}
            >
              Add
            </Tab>
            <Divider />
            <Tab
              active={activeView === 'list'}
              onClick={() => setActiveView('list')}
            >
              List
            </Tab>
          </ToolbarContainer>
          <ToolbarControls layoutId={componentId} layoutIndex={layoutIndex} />
        </LayoutItemToolbarContainer>
        <Content>
          {activeView === 'add' && (
            <div>
              <DelayWrapper>
                <Label>{t('delay')}</Label>
                <InputText
                  type={'number'}
                  value={delay}
                  onChange={handleDelayChange}
                  disableUnderline
                />
              </DelayWrapper>
              <ButtonsContainer>
                {eventList.data?.results
                  ?.filter(
                    ({ type }) =>
                      !OMITTED_TICKER_EVENT_TYPES.includes(type as number)
                  )
                  .map((event, index) => (
                    <StyledButton
                      key={event.id}
                      color='secondary'
                      variant='contained'
                      backgroundColor={TICKER_COLORS[index]}
                      onClick={addManualHighlight(event.id)}
                    >
                      <ActionIcon
                        name={
                          eventIndexToIconDictionary[event?.type ?? ''] ??
                          defaultIconName
                        }
                      />
                      <ActionText>{tServer(event.name)}</ActionText>
                    </StyledButton>
                  ))}
              </ButtonsContainer>
            </div>
          )}
          {activeView === 'list' && (
            <ListContainer>
              {sortedTickers.map(ticker => {
                const event = eventList.data?.results?.find(
                  event => event.id === ticker.event_id
                )
                return (
                  <TickerWrapper key={ticker.id}>
                    <TickerElement
                      onClick={
                        ticker.start_time
                          ? handleEventClick(ticker.start_time)
                          : undefined
                      }
                    >
                      <ActionIcon
                        name={
                          eventIndexToIconDictionary[event?.type ?? ''] ??
                          defaultIconName
                        }
                      />
                      <TickerText>
                        {tServer(event?.name ?? { en: '', de: '' })}
                      </TickerText>
                      <TickerTime>
                        ({convertSecondsToTime(ticker?.start_time ?? 0)})
                      </TickerTime>
                    </TickerElement>
                    <ResourceControls
                      onDelete={deleteTicker(ticker?.id ?? '')}
                      onTransfer={
                        canTransfer
                          ? undefined
                          : event => handleTransferOpen(event, ticker)
                      }
                    />
                  </TickerWrapper>
                )
              })}
            </ListContainer>
          )}
        </Content>
      </Root>
      <Menu
        open={Boolean(transferModalAnchor)}
        onClose={handleTransferClose}
        anchorEl={transferModalAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {organizations.map(organization => (
          <MenuItem
            key={organization.id}
            onClick={() => setSelectedOrganization(organization)}
          >
            {organization.name}
          </MenuItem>
        ))}
      </Menu>
      <TransferResourceModal
        open
        type='highlight'
        resource={selectedTicker}
        organization={selectedOrganization}
        onClose={handleTransferClose}
      />
    </>
  )
}
