import { RelationEditHooks } from './RelationEdit.interface'

import {
  useAccessList,
  useAdminUserAccessDelete,
  useAdminUserAccessList,
  useAdminUserAccessUpdate,
} from 'modules/admin/hooks'

import { useAssociationList } from 'modules/user/hooks'
import { useCompetitionList } from 'modules/competition/hooks'
import { useClubList } from 'modules/user/hooks'
import { useTeamList } from 'modules/team/hooks'

import * as UserAssociation from 'modules/admin/hooks/user/association'
import * as UserCompetition from 'modules/admin/hooks/user/competition'
import * as UserClub from 'modules/admin/hooks/user/club'
import * as UserTeam from 'modules/admin/hooks/user/team'

import * as OrganizationAssociation from 'modules/admin/hooks/organization/association'
import * as OrganizationCompetition from 'modules/admin/hooks/organization/competition'
import * as OrganizationClub from 'modules/admin/hooks/organization/club'
import * as OrganizationTeam from 'modules/admin/hooks/organization/team'

export const RELATION_EDIT_HOOKS: RelationEditHooks = {
  association: {
    useList: useAssociationList,
    user: {
      useCreate: UserAssociation.useAdminUserAssociationCreate,
      useDelete: UserAssociation.useAdminUserAssociationDelete,
      useListRelations: UserAssociation.useAdminUserAssociationList,
    },
    organization: {
      useCreate: OrganizationAssociation.useAdminOrganizationAssociationCreate,
      useDelete: OrganizationAssociation.useAdminOrganizationAssociationDelete,
      useListRelations:
        OrganizationAssociation.useAdminOrganizationAssociationList,
    },
  },
  competition: {
    useList: useCompetitionList,
    user: {
      useCreate: UserCompetition.useAdminUserCompetitionCreate,
      useDelete: UserCompetition.useAdminUserCompetitionDelete,
      useListRelations: UserCompetition.useAdminUserCompetitionList,
    },
    organization: {
      useCreate: OrganizationCompetition.useAdminOrganizationCompetitionCreate,
      useDelete: OrganizationCompetition.useAdminOrganizationCompetitionDelete,
      useListRelations:
        OrganizationCompetition.useAdminOrganizationCompetitionList,
    },
  },
  club: {
    useList: useClubList,
    user: {
      useCreate: UserClub.useAdminUserClubCreate,
      useDelete: UserClub.useAdminUserClubDelete,
      useListRelations: UserClub.useAdminUserClubList,
    },
    organization: {
      useCreate: OrganizationClub.useAdminOrganizationClubCreate,
      useDelete: OrganizationClub.useAdminOrganizationClubDelete,
      useListRelations: OrganizationClub.useAdminOrganizationClubList,
    },
  },
  team: {
    useList: useTeamList,
    user: {
      useCreate: UserTeam.useAdminUserTeamCreate,
      useDelete: UserTeam.useAdminUserTeamDelete,
      useListRelations: UserTeam.useAdminUserTeamList,
    },
    organization: {
      useCreate: OrganizationTeam.useAdminOrganizationTeamCreate,
      useDelete: OrganizationTeam.useAdminOrganizationTeamDelete,
      useListRelations: OrganizationTeam.useAdminOrganizationTeamList,
    },
  },
  access_group: {
    useList: useAccessList,
    listItemRenderPath: 'group',
    user: {
      // TODO: Rename hook from `update` to `creates`
      useCreate: useAdminUserAccessUpdate,
      useDelete: useAdminUserAccessDelete,
      useListRelations: useAdminUserAccessList,
    },
    // TODO: Create Org hooks
    organization: {
      useCreate: useAdminUserAccessUpdate,
      useDelete: useAdminUserAccessDelete,
      useListRelations: useAdminUserAccessList,
    },
  },
}
