import { Match } from '@sporttotal-tv/dip-coaching-client'

export const isMatchLive = (match?: Match) => {
  if (!match) return false

  const { event_start, event_end } = match

  const now = new Date()
  const start = new Date(event_start * 1000)
  const end = new Date(event_end * 1000)

  return now >= start && now <= end
}
