import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { useMatchList } from 'modules/match/hooks'
import { isInternalMatch } from 'modules/match/utils'
import { CalendarEvent } from 'modules/calendar/types'
import { EVENT_VIEW_DATE_FORMAT } from '../constants'

type useMarkersProps = {
  startDate?: Date
  endDate?: Date
}

export const useMarkers = (props?: useMarkersProps) => {
  const [markers, setMarkers] = useState<CalendarEvent[]>([])

  const matchList = useMatchList({
    page_limit: 100,
    start_date: props?.startDate
      ? Math.round(props?.startDate.getTime() / 1000)
      : undefined,
    end_date: props?.endDate
      ? Math.round(props?.endDate.getTime() / 1000)
      : undefined,
    expand: ['teams'],
  })

  useEffect(() => {
    const newMarkers = matchList.data?.pages.reduce<CalendarEvent[]>(
      (allMarkers, page) => {
        const pageMarkers = page.results?.reduce<CalendarEvent[]>(
          (acc, match) => {
            const isMatchInternal = isInternalMatch(match?.system_ref)

            const eventStartDate = new Date(match.event_start * 1000)
            const eventEndDate = new Date(match.event_end * 1000)

            return [
              ...acc,
              {
                id: match.id,
                title: match.title,
                description: match?.teams!.join('vs'),
                begin: format(eventStartDate, EVENT_VIEW_DATE_FORMAT),
                end: format(eventEndDate, EVENT_VIEW_DATE_FORMAT),
                editable: isMatchInternal,
              },
            ]
          },
          []
        )

        return [...allMarkers, ...pageMarkers]
      },
      []
    )

    setMarkers(newMarkers || [])
  }, [matchList.data])

  return markers
}
