import { api } from 'modules/core/api'
import { transformApi } from 'modules/core/helpers'

import { AdminOrganizationAssociationService } from './admin-organization-association.service'
import { AdminOrganizationCompetitionService } from './admin-organization-competition.service'
import { AdminOrganizationClubService } from './admin-organization-club.service'
import { AdminOrganizationTeamService } from './admin-organization-team.service'
import { AdminOrganizationUserService } from './admin-organization-user.service'

export const AdminOrganizationService = {
  retrieve: transformApi(api.adminOrganizations.retreive),
  list: transformApi(api.adminOrganizations.list),
  create: transformApi(api.adminOrganizations.create),
  delete: transformApi(api.adminOrganizations.delete),

  association: AdminOrganizationAssociationService,
  competition: AdminOrganizationCompetitionService,
  club: AdminOrganizationClubService,
  team: AdminOrganizationTeamService,
  user: AdminOrganizationUserService,
}
