import { forwardRef, useEffect } from 'react'
import { SelectProps } from '@mui/material'
// styled
import { OptionsSelector, OptionsSelectorMenuItem } from 'modules/core/styled'
// hooks
import { useCurrencyList } from 'modules/core/hooks'

export const CurrencySelector = forwardRef<HTMLInputElement, SelectProps>(
  ({ value, onChange, name, ...props }, ref) => {
    const currencies = useCurrencyList({
      page_limit: 100,
    })

    useEffect(() => {
      if (
        value === undefined &&
        !!onChange &&
        !!currencies.data?.results[0].id
      ) {
        onChange(
          {
            //@ts-ignore
            target: {
              value: currencies.data?.results[0].id,
              name: name || '',
            },
          },
          null
        )
      }
    }, [currencies.data, value, onChange, name])

    return (
      <OptionsSelector
        sx={{
          '& .MuiSelect-select': { padding: '8px !important' },
          borderLeft: '1px solid #2C2C2C',
        }}
        IconComponent={'div'}
        ref={ref}
        {...props}
        value={value ?? ''}
        onChange={onChange}
      >
        {currencies.data?.results.map(item => (
          <OptionsSelectorMenuItem key={item.id} value={item.id}>
            {item.currency_symbol}
          </OptionsSelectorMenuItem>
        ))}
      </OptionsSelector>
    )
  }
)
