import { MatchList, Asset } from '@sporttotal-tv/dip-coaching-client'
import { QueryKey } from 'react-query'
import { InfiniteQuery } from 'modules/core/types'

export const getAssetFromMatchCollection = (
  matchCollection: [QueryKey, InfiniteQuery<MatchList>][],
  id: string
) =>
  matchCollection?.reduce<Asset | undefined>((asset, matchQuery) => {
    if (asset) return asset

    const [, matchPages] = matchQuery

    return matchPages.pages.reduce<Asset | undefined>((matchAsset, page) => {
      if (matchAsset) return matchAsset

      return page?.results.reduce<Asset | undefined>(
        (pageMatchAsset, pageMatch) => {
          if (pageMatchAsset) return pageMatchAsset

          return pageMatch?.assets?.find(asset => asset.id === id)
        },
        undefined
      )
    }, undefined)
  }, undefined)
