import { FC } from 'react'
import { ModalProps } from '@mui/material'
// Components
import { Icon, IconButton } from 'modules/core/components'

import {
  Root,
  ModalHeader,
  ModalTitle,
  ModalTitleWrapper,
  ModalBody,
  ModalContent,
} from './ModalDialog.styled'
import { ModalDialogProps } from './ModalDialog.interface'

export const ModalDialog: FC<ModalDialogProps & ModalProps> = ({
  open,
  onClose,
  title,
  children,
  icon = 'pencil',
  ...props
}) => {
  return (
    <Root open={open} onClose={onClose} {...props}>
      <ModalContent className='ModalContent'>
        <ModalHeader>
          <ModalTitleWrapper>
            <Icon name={icon} />
            <ModalTitle>{title}</ModalTitle>
          </ModalTitleWrapper>
          <IconButton
            sx={{ width: '26px', height: '26px' }}
            name='close-circle'
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Root>
  )
}
