import { Button, FormLabel, Input, styled, Typography } from '@mui/material'
import { Icon } from 'modules/core/components/Icon'
import { getContrastColor } from 'modules/core/helpers/getContrastColor'

export const Root = styled('div')`
  height: 100%;
`

export const ToolbarContainer = styled('div')`
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
`

export const Tab = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})<{ active: boolean }>`
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  color: ${({ theme, active }) =>
    active ? theme.palette.text.primary : theme.palette.text.disabled};
`

export const Divider = styled('div')`
  height: 14px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  width: 1px;
  margin: 0 5px;
`

export const Content = styled('div')`
  padding: ${({ theme }) => theme.spacing(1)};
  height: calc(100% - 46px);
  overflow: auto;
`

export const ButtonsContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: ${({ theme }) => theme.spacing(1)};
  row-gap: ${({ theme }) => theme.spacing(1)};
`

export const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2)};
`

export const TickerWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.light};
`

export const TickerElement = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  flex: 1;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light}50;
  }
`

export const TickerText = styled(Typography)`
  font-size: 16px;
  line-height: 16px;
`

export const TickerTime = styled('span')`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'backgroundColor',
})<{ backgroundColor: string }>`
  font-size: 18px;
  box-shadow: none;
  padding: ${({ theme }) => theme.spacing(1.5)};
  border-radius: 0;
  text-transform: capitalize;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.palette.secondary.main};

  color: ${({ theme, backgroundColor }) =>
    getContrastColor(backgroundColor || theme.palette.secondary.main)};

  & path {
    fill: ${({ theme, backgroundColor }) =>
      getContrastColor(backgroundColor || theme.palette.secondary.main)};
  }

  &:hover {
    background-color: ${({ theme, backgroundColor }) =>
      backgroundColor ? `${backgroundColor}90` : theme.palette.secondary.main};
  }
`

export const ActionIcon = styled(Icon)`
  height: 11px;
  width: 11px;
`

export const ActionText = styled(Typography)``

export const Label = styled(FormLabel)`
  font-size: 12px;
  text-align: right;
  padding: 10px 0;
  position: relative;
`

export const InputText = styled(Input)`
  background-color: ${({ theme }) => theme.palette.darkest};
  padding: 0;
  width: 50px;

  & .MuiInput-input {
    padding: 4px;
    font-size: 14px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`

export const DelayWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`
