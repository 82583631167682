import { useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
// components
import { ComponentsView } from 'modules/layout/components'
// hooks
import { useTabLayoutsStore } from 'modules/layout/hooks'
import { useReduxDispatch } from 'modules/core/hooks'
// types
import { LayoutConfigBreakpoints } from 'modules/layout/types'
// constants
import {
  BREAKPOINTS_VALUES,
  DEFAULT_LAYOUT_TYPES,
  DEFAULT_TAB_LAYOUT_DATA,
  LayoutIndexPaletteItem,
} from 'modules/layout/constants'
// redux
import {
  setBreakpoint,
  setLayoutKeys,
  setLayoutsWithBreakpoints,
  setLayoutTypes,
  setLayoutCache,
} from 'modules/layout/redux'
// context
import { analyserLayoutKeys } from 'modules/analyser/constants'
import { VideoPlayersContextProvider } from 'modules/video-player/context'

const EventAnalyser = () => {
  const { tab } = useParams()
  const dispatch = useReduxDispatch()
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const { layoutsWithBreakpoints, isLoading, layoutTypes, layoutCache } =
    useTabLayoutsStore({
      tab: tab ?? '',
      tabsDataKey: analyserLayoutKeys.tabsDataKey,
      layoutTypesKey: analyserLayoutKeys.layoutTypesKey,
      defaultTabLayoutData: DEFAULT_TAB_LAYOUT_DATA,
      defaultLayoutTypes: DEFAULT_LAYOUT_TYPES,
    })

  const breakpoint = useMemo(() => {
    const breakpointValue = Object.entries(BREAKPOINTS_VALUES).find(
      ([, value]) => {
        return window.innerWidth >= value
      }
    )

    return (breakpointValue?.[0] as LayoutConfigBreakpoints) ?? 'xxs'
  }, [])

  useEffect(() => {
    if (isInitialized || isLoading) return
    dispatch(setBreakpoint(breakpoint))
    dispatch(setLayoutTypes(layoutTypes))
    dispatch(setLayoutsWithBreakpoints(layoutsWithBreakpoints))
    dispatch(setLayoutKeys(analyserLayoutKeys))
    dispatch(setLayoutCache(layoutCache))
    setIsInitialized(true)
  }, [
    layoutCache,
    isLoading,
    isInitialized,
    breakpoint,
    layoutTypes,
    layoutsWithBreakpoints,
    dispatch,
  ])

  if (isLoading || !isInitialized) return null

  return (
    <VideoPlayersContextProvider>
      <ComponentsView paletteInfo={LayoutIndexPaletteItem} />
    </VideoPlayersContextProvider>
  )
}

export { EventAnalyser }
