import { FC, MouseEvent, useCallback, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { Collapse, Tooltip } from '@mui/material'
import { useDrop } from 'react-dnd'
import { Asset, Organization } from '@sporttotal-tv/dip-coaching-client'
// components
import {
  Icon,
  IconButton,
  Menu,
  MenuItem,
  ResourceControls,
} from 'modules/core/components'
import { ClipPlaylistPreview } from 'modules/asset/components'
import { TransferResourceModal } from 'modules/resource/components'
// constants
import { DragItem } from 'modules/core/constants'
// hooks
import {
  usePlaylistDelete,
  usePlaylistAssetCreate,
  usePlaylistUpdate,
} from 'modules/playlist/hooks'
import { useOutsideClick } from 'modules/core/hooks'
import { useTError } from 'modules/core/i18n/hooks'
import { useOrganizationContext } from 'modules/organization/hooks'
// schemas
import { playlistSchema } from 'modules/playlist/schemas'

import {
  PlaylistPreviewProps,
  PlaylistUpdateFormData,
} from './PlaylistPreview.interface'
import {
  AnimatedChevronIcon,
  CollapseControlsWrapper,
  ContentWrapper,
  IconWrapper,
  PlaylistDescriptionItem,
  PlaylistDescriptionWrapper,
  PlaylistInfoItem,
  PlaylistInfoWrapper,
  Root,
  Title,
  TitleIcon,
  TitleRow,
  TitleWrapper,
} from './PlaylistPreview.styled'
// TODO: Temporary get styled data from other modal,
//  as I think when design is ready there will be other way to edit playlist
import {
  ButtonWrapper,
  FormWrapper,
  FullWidthFormControl,
  InfoErrorIcon,
  InputText,
  InputWrapper,
  Label,
  StyledModal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  ModalTitleWrapper,
  SaveButton,
  SaveIcon,
} from 'modules/playlist/components/PlaylistToolbar/PlaylistToolbar.styled'

export const PlaylistPreview: FC<PlaylistPreviewProps> = ({
  playlist,
  filter,
}) => {
  const [collapsed, setIsCollapsed] = useState<boolean>(true)
  const playlistDelete = usePlaylistDelete()
  const { tError } = useTError('components', { keyPrefix: 'playlist' })

  const playlistAssetCreate = usePlaylistAssetCreate()
  const playlistUpdate = usePlaylistUpdate()
  const { activeOrganizationId, organizations } = useOrganizationContext()
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false)
  const [transferModalAnchor, setTransferModalAnchor] = useState<HTMLElement>()

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>()

  const modalRef = useRef<HTMLDivElement>(null)
  const handleOutsideClick = useCallback(() => {
    setEditModalOpen(false)
  }, [])
  useOutsideClick(modalRef, handleOutsideClick)
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: [DragItem.MATCH_ASSET, DragItem.PLAYLIST_ASSET],
    drop: (clip: Asset) =>
      playlistAssetCreate.mutate({
        asset_id: clip.id,
        playlist_id: playlist.id,
      }),
    canDrop: (clip: Asset) =>
      playlist.assets?.findIndex(asset => asset.id === clip.id) === -1,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PlaylistUpdateFormData>({
    resolver: yupResolver(playlistSchema),
  })

  const onSubmit = (data: PlaylistUpdateFormData) => {
    playlistUpdate.mutate({
      id: playlist.id,
      params: data,
    })
    setEditModalOpen(false)
  }

  const handleDelete = useCallback(() => {
    playlistDelete.mutate(playlist.id)
  }, [playlistDelete, playlist.id])

  const hasFilters = useMemo(() => {
    return !!(filter?.events && filter.events.length > 0)
  }, [filter])

  const filteredAssets = useMemo(() => {
    let assets = playlist.assets || []
    if (!filter?.sportId) return assets

    if (filter?.events && filter.events.length > 0) {
      assets = assets.filter(asset => {
        const eventFilter = filter?.events?.some(
          event => asset.event_id === event
        )

        return eventFilter
      })
    }

    return assets
  }, [playlist.assets, filter])

  const droppable = useMemo(
    () => canDrop && !playlist.id.includes('draft-'),
    [playlist.id, canDrop]
  )

  const canTransfer = useMemo(
    () => organizations.length && !activeOrganizationId,
    [activeOrganizationId, organizations.length]
  )

  const handleTransferOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) =>
      setTransferModalAnchor(event.target as HTMLElement),
    []
  )

  const handleTransferClose = useCallback(() => {
    setTransferModalAnchor(undefined)
    setSelectedOrganization(undefined)
  }, [])

  return (
    <>
      <Collapse in={!(hasFilters && filteredAssets.length === 0)}>
        <Root droppable={droppable} isDragOver={isOver && canDrop} ref={drop}>
          <PlaylistInfoWrapper>
            <PlaylistInfoItem>
              <TitleRow>
                <TitleWrapper>
                  <TitleIcon name='playlist-filled' />
                  <Title>{playlist.title}</Title>
                </TitleWrapper>
                <ResourceControls
                  onEdit={
                    playlist.id.includes('draft')
                      ? undefined
                      : () => setEditModalOpen(true)
                  }
                  onTransfer={canTransfer ? undefined : handleTransferOpen}
                  onDelete={handleDelete}
                />
              </TitleRow>
              <ContentWrapper>
                <PlaylistDescriptionWrapper>
                  <PlaylistDescriptionItem>
                    {format(new Date(+playlist.created_at * 1000), 'dd/MM/yy')}
                  </PlaylistDescriptionItem>
                  <PlaylistDescriptionItem>
                    {playlist.description}
                  </PlaylistDescriptionItem>
                </PlaylistDescriptionWrapper>
                <CollapseControlsWrapper>
                  {filteredAssets && filteredAssets.length !== 0 && (
                    <IconWrapper>
                      <AnimatedChevronIcon
                        name='chevron-up'
                        onClick={() => setIsCollapsed(prev => !prev)}
                        chevronUp={collapsed}
                      />
                    </IconWrapper>
                  )}
                </CollapseControlsWrapper>
              </ContentWrapper>
            </PlaylistInfoItem>
            {/*TODO: change edit when design for it is ready*/}
            <StyledModal
              closeAfterTransition
              open={editModalOpen}
              onClose={() => setEditModalOpen(false)}
            >
              <ModalContent ref={editModalOpen ? modalRef : undefined}>
                <ModalHeader>
                  <ModalTitleWrapper>
                    <Icon name='pencil' />
                    <ModalTitle>Edit Playlist</ModalTitle>
                  </ModalTitleWrapper>

                  <IconButton
                    sx={{ width: '26px', height: '26px' }}
                    name='close-circle'
                    onClick={() => setEditModalOpen(false)}
                  />
                </ModalHeader>
                <FormWrapper>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <InputWrapper>
                      <Label sx={{ width: '50%' }}>Title</Label>
                      <FullWidthFormControl>
                        <InputText
                          placeholder=''
                          defaultValue={playlist.title}
                          disableUnderline
                          inputProps={{ 'aria-label': 'title' }}
                          {...register('title')}
                        />
                        <Tooltip title={tError(errors.title?.message) ?? ''}>
                          <InfoErrorIcon hidden={!errors.title?.message} />
                        </Tooltip>
                      </FullWidthFormControl>
                    </InputWrapper>

                    <InputWrapper>
                      <Label sx={{ width: '50%' }}>Description</Label>
                      <FullWidthFormControl>
                        <InputText
                          placeholder=''
                          disableUnderline
                          defaultValue={playlist.description}
                          multiline
                          minRows={2}
                          maxRows={2}
                          inputProps={{ 'aria-label': 'description' }}
                          {...register('description')}
                        />
                        <Tooltip
                          title={tError(errors.description?.message) ?? ''}
                        >
                          <InfoErrorIcon
                            hidden={!errors.description?.message}
                          />
                        </Tooltip>
                      </FullWidthFormControl>
                    </InputWrapper>
                    <ButtonWrapper>
                      <SaveButton
                        type='submit'
                        color='secondary'
                        variant='contained'
                      >
                        <SaveIcon name='save' />
                        Save
                      </SaveButton>
                    </ButtonWrapper>
                  </form>
                </FormWrapper>
              </ModalContent>
            </StyledModal>
          </PlaylistInfoWrapper>
        </Root>
      </Collapse>
      {filteredAssets && (
        <Collapse in={!collapsed}>
          {filteredAssets.map(asset => (
            <ClipPlaylistPreview
              playlistId={playlist.id}
              key={asset.id}
              clip={asset}
            />
          ))}
        </Collapse>
      )}
      <Menu
        open={Boolean(transferModalAnchor)}
        onClose={handleTransferClose}
        anchorEl={transferModalAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {organizations.map(organization => (
          <MenuItem
            key={organization.id}
            onClick={() => setSelectedOrganization(organization)}
          >
            {organization.name}
          </MenuItem>
        ))}
      </Menu>
      <TransferResourceModal
        open={Boolean(transferModalAnchor)}
        onClose={handleTransferClose}
        type='playlist'
        resource={playlist}
        organization={selectedOrganization}
      />
    </>
  )
}
