// Events to not show in the ticker component
export const OMITTED_TICKER_EVENT_TYPES: number[] = [12, 13, 14]
export const TICKER_PAGE_LIMIT = 100

export const TICKER_COLORS = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#C0C0C0',
  '#808080',
  '#800000',
  '#808000',
  '#008000',
  '#800080',
  '#008080',
  '#000080',
  '#FF6666',
  '#FFFF66',
  '#66FF66',
  '#66FFFF',
  '#6666FF',
  '#FF66FF',
  '#FFCCCC',
  '#FFFFCC',
  '#CCFFCC',
  '#CCFFFF',
  '#CCCCFF',
  '#FFCCFF',
]
