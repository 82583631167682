import { api } from 'modules/core/api'
import { transformApi } from 'modules/core/helpers'

export const OrganizationApiService = {
  list: transformApi(api.organization.list),
  create: transformApi(api.organization.create),
  update: transformApi(api.organization.update),
  delete: transformApi(api.organization.delete),
  userInviteRedeem: transformApi(api.organizationGroupUserInvite.redeem),
}
