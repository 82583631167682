import { ApiService } from 'services/Api.service'
import { createMutationHookWithBody } from 'modules/core/api/hooks/utils'
import { merge } from 'lodash'
import { CAMERA_CACHE_KEY } from './'

export const [useCameraUpdate] = createMutationHookWithBody(
  ApiService.camera.update,
  (queryClient, params) => ({
    onMutate: variables => {
      const currentData = queryClient.getQueryData([CAMERA_CACHE_KEY, params])
      const updatedData = merge(currentData, variables)

      queryClient.setQueryData([CAMERA_CACHE_KEY, params], updatedData)
    },
    onSuccess: () => {
      // Camera shadow update takes a moment before changes are present on camera shadow fetch
      setTimeout(
        () => queryClient.invalidateQueries(CAMERA_CACHE_KEY, { exact: false }),
        750
      )
    },
  })
)
