import React, { memo, useCallback } from 'react'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import format from 'date-fns/format'
import getWeekDays from '../utils/getWeekDays'
import getSelectedWeekIndex from '../utils/getSelectedWeekIndex'
import { calendarLayouts } from '../utils/constants'
import {
  Button,
  css,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  addDays,
  addMonths,
  addWeeks,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns'
import {
  selectCalendarLayout,
  selectCalendarSelectedDate,
  setCalendarLayout,
  setCalendarSelectedDate,
} from 'modules/calendar/redux'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
import { CalendarLayoutVariant } from 'modules/calendar/types'

const drawerWidth = 260

const Root = styled('div', {
  shouldForwardProp: prop => prop !== 'open',
})<{ open?: boolean }>`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  transition: ${({ theme }) =>
    theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};

  ${({ theme, open }) =>
    open &&
    css`
      width: calc(100% - ${drawerWidth}px);
      margin-left: ${drawerWidth}px;
      transition: ${theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })};
    `}
`

const Title = styled(Typography)`
  flex-grow: 1;
  padding-left: ${({ theme }) => theme.spacing(1)};
  font-weight: 400;
  font-size: ${({ theme }) => theme.spacing(3)};
  text-transform: capitalize;
`

export const LayoutSelector = styled(Select)`
  background-color: ${({ theme }) =>
    theme.createAlpha(theme.palette.primary.light, 0.5)};
  border-radius: 6px;
  padding: 0;
  font-size: 14px;
  outline: none;
  min-width: 100px;

  & .MuiSelect-select {
    padding: 8px;
    border: 1px solid transparent;
  }

  & .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
    border-radius: 0;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  background-color: ${({ theme }) =>
    theme.createAlpha(theme.palette.primary.light, 0.5)};
  transition: all 0.3s ease-in-out;

  &.Mui-selected {
    background-color: ${({ theme }) =>
      theme.createAlpha(theme.palette.primary.light, 0.2)};
  }
`

export const TodayButton = styled(Button)`
  background-color: ${({ theme }) =>
    theme.createAlpha(theme.palette.primary.light, 0.5)};

  text-transform: capitalize;
`

const CalendarToolbar = memo(() => {
  const dispatch = useReduxDispatch()
  const selectedDate = useReduxSelector(selectCalendarSelectedDate)
  const layout = useReduxSelector(selectCalendarLayout)
  const { t } = useTranslation('pages', { keyPrefix: 'calendar' })

  const goToToday = () => {
    const newDate = new Date()
    dispatch(setCalendarSelectedDate(newDate.getTime()))
  }

  const next = () => {
    let newDate

    switch (layout) {
      case 'week':
        newDate = addWeeks(selectedDate, 1)
        break

      case 'day':
        newDate = addDays(selectedDate, 1)
        break

      default:
        newDate = addMonths(selectedDate, 1)
        break
    }
    dispatch(setCalendarSelectedDate(newDate.getTime()))
  }

  const previous = () => {
    let newDate

    switch (layout) {
      case 'week':
        newDate = subWeeks(selectedDate, 1)
        break

      case 'day':
        newDate = subDays(selectedDate, 1)
        break

      default:
        newDate = subMonths(selectedDate, 1)
        break
    }
    dispatch(setCalendarSelectedDate(newDate.getTime()))
  }

  const setLayout = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      dispatch(setCalendarLayout(event.target.value as CalendarLayoutVariant))
    },
    [dispatch]
  )

  const weeks = getWeekDays(selectedDate, 7)
  const selectedWeekIndex = getSelectedWeekIndex(selectedDate, weeks, 0)
  const selectedWeek = weeks[selectedWeekIndex]

  const firstDayOfWeekMonth = format(selectedWeek[0], 'MMM')
  const lastDayOfWeekMonth = format(selectedWeek[6], 'MMM')
  const firstDayOfWeekYear = format(selectedWeek[0], 'yyyy')
  const lastDayOfWeekYear = format(selectedWeek[6], 'yyyy')

  const showMonthsAndYears =
    layout === 'week' &&
    firstDayOfWeekMonth !== lastDayOfWeekMonth &&
    firstDayOfWeekYear !== lastDayOfWeekYear
      ? `${firstDayOfWeekMonth} ${firstDayOfWeekYear} - ${lastDayOfWeekMonth} ${lastDayOfWeekYear}`
      : false
  const showMonthsAndYear =
    !showMonthsAndYears &&
    layout === 'week' &&
    firstDayOfWeekMonth !== lastDayOfWeekMonth
      ? `${firstDayOfWeekMonth} - ${lastDayOfWeekMonth} ${firstDayOfWeekYear}`
      : false
  const showMonthAndYear = !showMonthsAndYear
    ? format(selectedDate, 'MMMM yyyy')
    : false

  return (
    <Root>
      <Toolbar>
        <Tooltip title={`${format(new Date(), 'iii, d MMMM')}`}>
          <TodayButton color='inherit' aria-label='Today' onClick={goToToday}>
            {t('today')}
          </TodayButton>
        </Tooltip>

        <Tooltip title={t(`previous.${layout}`)}>
          <IconButton onClick={previous}>
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title={t(`next.${layout}`)}>
          <IconButton onClick={next}>
            <ChevronRightIcon />
          </IconButton>
        </Tooltip>

        <Title>
          {showMonthsAndYears || showMonthsAndYear || showMonthAndYear}
        </Title>

        <LayoutSelector
          inputProps={{ 'aria-label': 'role' }}
          value={layout}
          onChange={setLayout}
          MenuProps={{
            PaperProps: {
              sx: {
                marginTop: '4px',
                borderRadius: '6px',
                '& .MuiList-root': {
                  padding: 0,
                },
              },
            },
          }}
        >
          {calendarLayouts.map((calendarLayout, index) => (
            <StyledMenuItem
              key={`${calendarLayout.value}-${index}`}
              value={calendarLayout.value}
            >
              {calendarLayout.label}
            </StyledMenuItem>
          ))}
        </LayoutSelector>
      </Toolbar>
    </Root>
  )
})

export default CalendarToolbar
